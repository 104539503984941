import request from '@/utils/request'

export function getBachelorDegreesById(data) {
  return request({
    url: `/api/BachelorDegrees/GetBachelorDegreeById/${data}`,
    method: 'get',
  })
}

export function getBachelorDegrees() {
  return request({
    url: '/api/BachelorDegrees/GetBachelorDegrees',
    method: 'get',
  })
}

export function addBachelorDegree(data) {
  return request({
    url: '/api/BachelorDegrees/AddBachelorDegree',
    method: 'post',
    data,
  })
}

export function updateBachelorDegree(data) {
  return request({
    url: '/api/BachelorDegrees/UpdateBachelorDegree',
    method: 'put',
    data,
  })
}
export function deleteBachelorDegree(data) {
  return request({
    url: `/api/BachelorDegrees/DeleteBachelorDegree?bachelorDegreeId=${data}`,
    method: 'delete',
  })
}
